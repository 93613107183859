// Stylesheets
import './quizDialog.css';
import './quizDialogMobile.css';
import './quizDialogTablet.css'

// React
import {useEffect, useRef, useState} from "react";

// Firebase
import {collection, addDoc, updateDoc, doc} from "firebase/firestore";
import {getDownloadURL, getStorage, ref as storageRef, uploadString} from "firebase/storage";
import {db} from "../../firebase";

// Objects
import {bookingConverter} from "../../objects";
import {EmailConfirmation, EmailUpdate} from "./emailLayouts";
import {BookingConfirm, BookingInfo, BookingSuccess} from "./";
import {renderToString} from "react-dom/server";
import {formatDateTime} from "./functions";

const storage = getStorage();

export async function sendEmailAndCreateCalendarEvent(bookingInfo, docRef, setCalendarDownload = null) {
  const mailRef = collection(db, "mail");

  let calendarInfo = {
    title: 'Mosaic Tap Quiz',
    location: 'Mosaic Tap, Arch 7, Forth Goods Yard, NE1 3PG',
    startTime: formatDateTime(bookingInfo.date, bookingInfo.time),
    endTime: formatDateTime(bookingInfo.date, '10:30pm'),
    description: `Thanks for booking the Mosaic Tap Quiz! You have booked for <b>${bookingInfo.size}</b> on <b>${bookingInfo.date.split(' [SOLD OUT]')[0].replace('Wed', 'Wednesday')}</b> @ <b>${bookingInfo.time}</b>.<br/><br/>If you would like to update your booking, click <a href=\`https://mosaictap.com/booking/${docRef.id}/${bookingInfo.email}\`>here</a>, or go to: https://mosaictap.com/booking/${docRef.id}/${bookingInfo.email}`
  }

  // Generate the .ics content
  const icsContent = `BEGIN:VCALENDAR
VERSION:2.0
PRODID:-//Mosaic Tap//Quiz//EN
BEGIN:VEVENT
UID:${Date.now()}@mosaictap.com
DTSTAMP:${new Date().toISOString().replace(/-|:|\.\d+/g, '')}
DTSTART:${calendarInfo.startTime.replace(/-|:|\.\d+/g, '')}
DTEND:${calendarInfo.endTime.replace(/-|:|\.\d+/g, '')}
SUMMARY:${calendarInfo.title}
DESCRIPTION:${calendarInfo.description}
LOCATION:${calendarInfo.location}
END:VEVENT
END:VCALENDAR`;

  const icsDataUrl = `data:text/calendar;charset=utf-8,${encodeURIComponent(icsContent)}`;

  // Upload the .ics file to Firebase Storage
  const storageReference = storageRef(storage, `ics/${docRef.id}.ics`);
  await uploadString(storageReference, icsDataUrl, 'data_url')
    .then(async (snapshot) => {
      // Get the download URL
      const downloadURL = await getDownloadURL(snapshot.ref);

      if (setCalendarDownload !== null) {
        setCalendarDownload(downloadURL);
      }

      // Update calendarInfo with the download URL
      calendarInfo.downloadURL = downloadURL;

      let message = {
        to: bookingInfo.email,
        bcc: "isaac@mosaictap.com",
        message: {
          subject: `Mosaic Tap Quiz Booking Confirmation for ${bookingInfo.name} on ${bookingInfo.date.split(' [SOLD OUT]')[0]}`,
          html: renderToString(<EmailConfirmation bookingId={docRef.id} bookingInfo={bookingInfo}
                                                  calendarInfo={calendarInfo}/>)
        }
      }

      await addDoc(mailRef, message).then(() => {
        return true
      })
    });
}

const QuizDialog = (props) => {
  let {open, setOpen, existingBookingInfo, update, bookingId, width} = props;
  let [bookingInfo, setBookingInfo] = useState(null)
  let [translates, setTranslates] = useState(width >= 1400 ? ['0svw', '50svw', '100svw'] : width < 1400 && width >= 640 ? ['0svw', '75svw', '150svw'] : ['0svw', '90svw', '180svw'])
  let [pageNo, setPageNo] = useState(1)
  let [loading, setLoading] = useState(false)
  let quizInnerContainerRef = useRef(null);

  let [calendarDownload, setCalendarDownload] = useState(null)

  const movePage = (pageNo) => {
    let newTranslation = [];
    if (width >= 1400) {
      if (pageNo === 1) {
        newTranslation = ['0svw', '50svw', '100svw'];
      } else if (pageNo === 2) {
        newTranslation = ['-50svw', '0svw', '50svw'];
      } else if (pageNo === 3) {
        newTranslation = ['-100svw', '-50svw', '0svw'];
      }
    } else if (width < 1400 && width >= 640) {
      if (pageNo === 1) {
        newTranslation = ['0svw', '75svw', '150svw'];
      } else if (pageNo === 2) {
        newTranslation = ['-75svw', '0svw', '75svw'];
      } else if (pageNo === 3) {
        newTranslation = ['-150svw', '-75svw', '0svw'];
      }
    } else {
      if (pageNo === 1) {
        newTranslation = ['0svw', '90svw', '180svw'];
      } else if (pageNo === 2) {
        newTranslation = ['-90svw', '0svw', '90svw'];
      } else if (pageNo === 3) {
        newTranslation = ['-180svw', '-90svw', '0svw'];
      }
    }

    setTranslates(newTranslation);
  }

  useEffect(() => {
    if (existingBookingInfo) {
      setBookingInfo(existingBookingInfo)
      movePage(1);
    } else {
      setBookingInfo(null)
    }
  }, [existingBookingInfo]);

  const sendBooking = async () => {
    setLoading(true)
    if (quizInnerContainerRef !== null) {
      quizInnerContainerRef.current.scroll({
        top: 0
      });
    }

    if (bookingInfo !== null) {
      if (bookingId === undefined && !update) {
        const ref = collection(db, "bookings").withConverter(bookingConverter);
        await addDoc(ref, bookingInfo).then(async (docRef) => {
          await sendEmailAndCreateCalendarEvent(bookingInfo, docRef, setCalendarDownload).then((r) => {
            setLoading(false)
            movePage(3)
          });
        })
      } else {
        const ref = doc(db, "bookings", bookingId).withConverter(bookingConverter)  // E.g. "bookings/Name-Sep18" `${name}-${date.split(' ')[1]}${date.split(' ')[2]}}`
        await updateDoc(ref, {
          name: bookingInfo.name,
          email: bookingInfo.email,
          size: bookingInfo.size,
          date: bookingInfo.date.split(' [SOLD OUT]')[0],
          time: bookingInfo.time
        }).then(async (docRef) => {
          const mailRef = collection(db, "mail");

          let calendarInfo = {
            title: 'Mosaic Tap Quiz',
            location: 'Mosaic Tap, Arch 7, Forth Goods Yard, NE1 3PG',
            startTime: formatDateTime(bookingInfo.date, bookingInfo.time),
            endTime: formatDateTime(bookingInfo.date, '10:30pm'),
            description: `Thanks for booking the Mosaic Tap Quiz! You have booked for <b>${bookingInfo.size}</b> on <b>${bookingInfo.date.split(' [SOLD OUT]')[0].replace('Wed', 'Wednesday')}</b> @ <b>${bookingInfo.time}</b>.<br/><br/>If you would like to update your booking, click <a href=\`https://mosaictap.com/booking/${bookingId}/${bookingInfo.email}\`>here</a>, or go to: https://mosaictap.com/booking/${bookingId}/${bookingInfo.email}`
          }

          // Generate the .ics content
          const icsContent = `BEGIN:VCALENDAR
VERSION:2.0
PRODID:-//Mosaic Tap//Quiz//EN
BEGIN:VEVENT
UID:${Date.now()}@mosaictap.com
DTSTAMP:${new Date().toISOString().replace(/-|:|\.\d+/g, '')}
DTSTART:${calendarInfo.startTime.replace(/-|:|\.\d+/g, '')}
DTEND:${calendarInfo.endTime.replace(/-|:|\.\d+/g, '')}
SUMMARY:${calendarInfo.title}
DESCRIPTION:${calendarInfo.description}
LOCATION:${calendarInfo.location}
END:VEVENT
END:VCALENDAR`;

          const icsDataUrl = `data:text/calendar;charset=utf-8,${encodeURIComponent(icsContent)}`;

          // Upload the .ics file to Firebase Storage
          const storageReference = storageRef(storage, `ics/${bookingId}.ics`);
          await uploadString(storageReference, icsDataUrl, 'data_url')
            .then(async (snapshot) => {
              // Get the download URL
              const downloadURL = await getDownloadURL(snapshot.ref);
              setCalendarDownload(downloadURL);

              // Update calendarInfo with the download URL
              calendarInfo.downloadURL = downloadURL;

              let message = {
                to: bookingInfo.email,
                bcc: "isaac@mosaictap.com",
                message: {
                  subject: `Mosaic Tap Quiz Booking Updated for ${bookingInfo.name} on ${bookingInfo.date.split(' [SOLD OUT]')[0]}.`,
                  html: renderToString(<EmailUpdate bookingId={bookingId} bookingInfo={bookingInfo}
                                                    calendarInfo={calendarInfo}/>)
                }
              }

              await addDoc(mailRef, message).then(() => {
                setLoading(false)
                movePage(3)
              })
            });
        })
      }
    } else {
      setLoading(false)
      alert("Something went wrong, try refreshing the page and try again.")
    }
  }

  return (
    <div className={'quizDialogContainer'}
         style={{opacity: open ? '100%' : '0%', pointerEvents: open ? 'visiblePainted' : 'none'}} onClick={() => {
      setOpen(false)
    }}>
      <div className={'quizDialogInnerContainer'} style={{marginTop: open ? '0px' : '110vh'}} onClick={(e) => {
        e.stopPropagation();
      }}>
        <BookingInfo translate={translates[0]} movePage={movePage} bookingInfo={bookingInfo}
                     setBookingInfo={setBookingInfo} width={width} update={update} bookingId={bookingId}/>
        <BookingConfirm ref={quizInnerContainerRef} translate={translates[1]} movePage={movePage}
                        bookingInfo={bookingInfo}
                        sendBooking={sendBooking} width={width} loading={loading}/>
        <BookingSuccess translate={translates[2]} setOpen={setOpen}
                        bookingInfo={bookingInfo} calendarDownload={calendarDownload}/>
      </div>
    </div>
  )
}

export default QuizDialog;