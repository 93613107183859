import {collection, deleteDoc, doc, getDoc, getDocs, orderBy, query, setDoc, where} from "firebase/firestore";
import {db} from "../../firebase";
import {bookingConverter, TableLayout, tableLayoutConverter, Team} from "../../objects";

export const getCurrentBookings = async (date) => {
  let teams = []
  // Sort by bookingTime so teams that booked first are start of list
  const q = await query(
    collection(db, "bookings"),
    where("date", "==", date),
    orderBy("bookingTime"),
  );
  const querySnapshot = await getDocs(q)

  let id = 1;
  querySnapshot.forEach((doc) => {
    let booking = bookingConverter.fromFirestore(doc);

    if (booking.size.length > 1) {
      teams.push(new Team(doc.id, id, booking.name, booking.size, booking.size.reduce((a, b) => a + b, 0), booking.date, booking.time, booking.email))
      id += 1;
    } else {
      teams.push(new Team(doc.id, id, booking.name, booking.size, parseInt(booking.size[0]), booking.date, booking.time, booking.email))
      id += 1;
    }
  });
  return teams;
}

export const searchForBookingInformation = async (email) => {
  const q = await query(
    collection(db, "bookings"),
    where("email", "==", email),
    orderBy("bookingTime", "desc"),
  );
  const querySnapshot = await getDocs(q)
  if (querySnapshot.docs.length > 0) {
    return {bookingInfo: bookingConverter.fromFirestore(querySnapshot.docs[0]), id: querySnapshot.docs[0].id}
  } else {
    console.log("No such booking");
  }
}

export const getBookingInformation = async (bookingId) => {
  const docRef = doc(db, "bookings", bookingId);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    return bookingConverter.fromFirestore(docSnap);
  } else {
    console.log("No such booking");
  }
}

export const deleteBooking = async (bookingId) => {
  await deleteDoc(doc(db, "bookings", bookingId));
}

export const getLayout = async (date) => {
  const docSnap = await getDoc(doc(db, "layouts", date));
  if (docSnap.exists()) {
    return tableLayoutConverter.fromFirestore(docSnap);
  } else {
    return null;
  }
}

// Layout
export const assignTablesCustomToObjectForFirestore = (tables) => {
  let objectTables = tables.map((obj) => {
    return Object.assign({}, obj)
  })
  for (const id in objectTables) {
    const table = objectTables[id]
    let teamsObj = table.teams.map((obj) => {
      return Object.assign({}, obj)
    })
    table.teams = teamsObj
  }
  return objectTables
}

export const saveAndUploadTableLayout = async (date, assignedTables, emptyTables, emptySpaces, foldingChairs, totalPeople) => {
  let layout = new TableLayout(date, assignTablesCustomToObjectForFirestore(assignedTables), assignTablesCustomToObjectForFirestore(emptyTables), emptySpaces, foldingChairs, totalPeople);
  const ref = doc(db, "layouts", date).withConverter(tableLayoutConverter)
  await setDoc(ref, layout).then(() => {
    alert("Saved layout");
  })
}